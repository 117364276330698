import PropTypes from 'prop-types';
import './Input.scss';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';

// Register the Japanese locale
registerLocale('ja', ja);

const CustomInput = ({ label, name, type = 'text', placeholder = '', value, onChange, required = false, error = false, errorMessage = 'このフィールドは必須です', options = [], disabled = false, id = '' }) => {
    const renderInput = () => {
        switch (type) {
            case 'text':
                return <input name={name} type='text' placeholder={placeholder} value={value} onChange={onChange} className={`custom-text-input ${error ? 'error' : ''}`} disabled={disabled} id={id} />;
            case 'file':
                return (
                    <div className={`custom-file-input ${error ? 'error' : ''}`}>
                        <label className={`custom-file-label ${error ? 'error' : ''}`}>
                            <input name={name} type="file" onChange={onChange} className="file-input" disabled={disabled} id={id} />
                            <span className="custom-file-button">ファイルアップロード</span>
                            <span className="custom-file-text">{value ? value.name : 'ファイルが選択されていません'}</span>
                        </label>
                    </div>
                );
            case 'date':
                return (
                    <DatePicker
                        showIcon
                        selected={value}
                        onChange={(date) => onChange({ target: { name, value: date } })}
                        minDate={new Date()}
                        placeholderText={placeholder}
                        className={`custom-date-input ${error ? 'error' : ''}`}
                        locale='ja'
                        showYearDropdown
                        showMonthDropdown
                        disabled={disabled}
                        id={id}
                    />
                );
            case 'select':
                return (
                    <select name={name} value={value} onChange={onChange} className={`custom-select-input ${error ? 'error' : ''} ${value ? 'selected' : 'not-selected'}`} disabled={disabled} id={id}>
                        <option value="" disabled>{placeholder}</option>
                        {options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                );
            case 'textarea':
                return (
                    <textarea
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        className={`custom-textarea-input ${error ? 'error' : ''}`}
                        rows={4}
                        disabled={disabled}
                        id={id}
                    />
                );
            default:
                return <input name={name} type='text' placeholder={placeholder} value={value} onChange={onChange} className={`custom-text-input ${error ? 'error' : ''}`} disabled={disabled} id={id} />;
        }
    };

    return (
        <div className='custom-input'>
            {label && <label>{label} {required && <span className='required'>*</span>}</label>}
            <div className='input-area'>
                {renderInput()}
                {error && <div className='error-message'>{errorMessage}</div>}
            </div>
        </div>
    );
};

CustomInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'file', 'date', 'select', 'textarea']),
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(File)]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    disabled: PropTypes.bool,
    id: PropTypes.string
};

export default CustomInput;